import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
  token: localStorage.getItem('token'),
  isAuthenticated: !!localStorage.getItem('token'),
  loading: false,
  error: null,
  lastActivity: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      const { user, token } = action.payload;
      state.user = user;
      state.token = token;
      state.isAuthenticated = true;
      state.error = null;
      state.lastActivity = new Date().toISOString();
      localStorage.setItem('token', token);
      localStorage.setItem('user', JSON.stringify(user));
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    clearError: (state) => {
      state.error = null;
    },
    updateLastActivity: (state) => {
      state.lastActivity = new Date().toISOString();
    },
    logout: (state) => {
      // 상태 초기화
      state.user = null;
      state.token = null;
      state.isAuthenticated = false;
      state.loading = false;
      state.error = null;
      state.lastActivity = null;

      // localStorage 정리
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      
      // 추가로 필요한 다른 localStorage 항목들도 정리
      localStorage.removeItem('chat_history');
      localStorage.removeItem('chat_summary');
    },
    // 세션 만료 처리
    sessionExpired: (state) => {
      state.user = null;
      state.token = null;
      state.isAuthenticated = false;
      state.error = '세션이 만료되었습니다. 다시 로그인해주세요.';
      localStorage.clear();  // 관련된 모든 데이터 정리
    },
    // 자동 로그인 시도
    attemptAutoLogin: (state) => {
      const token = localStorage.getItem('token');
      const userStr = localStorage.getItem('user');
      
      if (token && userStr) {
        try {
          const user = JSON.parse(userStr);
          state.user = user;
          state.token = token;
          state.isAuthenticated = true;
          state.lastActivity = new Date().toISOString();
        } catch (e) {
          state.user = null;
          state.token = null;
          state.isAuthenticated = false;
          localStorage.clear();
        }
      }
    }
  },
});

// 액션 생성자 내보내기
export const {
  setCredentials,
  setLoading,
  setError,
  clearError,
  updateLastActivity,
  logout,
  sessionExpired,
  attemptAutoLogin
} = authSlice.actions;

// 선택자(Selectors)
export const selectCurrentUser = (state) => state.auth.user;
export const selectIsAuthenticated = (state) => state.auth.isAuthenticated;
export const selectAuthToken = (state) => state.auth.token;
export const selectAuthError = (state) => state.auth.error;
export const selectAuthLoading = (state) => state.auth.loading;

// 리듀서 내보내기
export default authSlice.reducer;