import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import io from 'socket.io-client';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { solarizedlight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { toast } from 'react-toastify';
import { FaPaperPlane } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import {
  setConnected,
  setTyping,
  setThinking,
  setSummarizing,
  setSummary,
  addMessage,
  updateCurrentStreamedMessage,
  finishCurrentStreamedMessage,
  setInputMessage,
  resetConversation
} from '../store/chatSlice';
import { logout } from '../store/authSlice';

function ChatApp() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [socket, setSocket] = useState(null);
    const socketRef = useRef(null);  // socket 인스턴스를 ref로 관리
    const reconnectTimerRef = useRef(null);  // 타이머도 ref로 관리
    const attemptCountRef = useRef(0);  // 시도 횟수도 ref로 관리
  
  const { 
    messages, 
    inputMessage, 
    isConnected, 
    isTyping, 
    isThinking, 
    isSummarizing, 
    summary,
    currentStreamedMessage
  } = useSelector(state => state.chat);
  const { token, user } = useSelector(state => state.auth);
  
  const messagesEndRef = useRef(null);
  const chatWindowRef = useRef(null);
  const textareaRef = useRef(null);
  const [thinkingDots, setThinkingDots] = useState('');
  const [typingDots, setTypingDots] = useState('');
  const [shouldAutoScroll, setShouldAutoScroll] = useState(true);
  const [isNearBottom, setIsNearBottom] = useState(true);

  const handleLogout = useCallback(() => {
    if (socketRef.current) {  // socket ref 사용
      socketRef.current.disconnect();
      socketRef.current = null;
    }
    if (reconnectTimerRef.current) {
      clearTimeout(reconnectTimerRef.current);
      reconnectTimerRef.current = null;
    }
    setSocket(null);  // socket state도 초기화
    dispatch(setConnected(false));  // 연결 상태도 초기화
    dispatch(logout());
    navigate('/login');
  }, [dispatch, navigate]);

  const scrollToBottom = useCallback(() => {
    if (shouldAutoScroll) {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [shouldAutoScroll]);

  // Auto scroll effect
  useEffect(() => {
    if (shouldAutoScroll) {
      scrollToBottom();
    }
  }, [messages, currentStreamedMessage, scrollToBottom, shouldAutoScroll]);

  // Textarea height adjustment
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = textareaRef.current.scrollHeight + 'px';
    }
  }, [inputMessage]);

  // Scroll handling
  useEffect(() => {
    const handleScroll = () => {
      if (chatWindowRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = chatWindowRef.current;
        const scrolledToBottom = scrollHeight - scrollTop - clientHeight < 20;
        setIsNearBottom(scrolledToBottom);
        setShouldAutoScroll(scrolledToBottom);
      }
    };

    const chatWindow = chatWindowRef.current;
    if (chatWindow) {
      chatWindow.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (chatWindow) {
        chatWindow.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  // Socket connection setup with improved error handling
  useEffect(() => {
    if (!token) {
      console.log('No token available, skipping connection');
      return;
    }
  
    const socket = io('https://k2s3-chat.duckdns.org', {
      transports: ['websocket'],
      upgrade: false,
      pingTimeout: 300000,
      pingInterval: 10000,
      reconnection: true,
      reconnectionAttempts: 5,
      reconnectionDelay: 1000,
      auth: {
        token: token
      },
      query: {
        token: token
      }
    });
  
    // socket을 ref에 저장
    socketRef.current = socket;
  
    const onConnect = () => {
      console.log('Connected to server');
      dispatch(setConnected(true));
    };
  
    const onDisconnect = () => {
      console.log('Disconnected from server');
      dispatch(setConnected(false));
    };
  
    const onThinkingStart = () => {
      dispatch(setThinking(true));
      dispatch(setTyping(false));
    };
  
    const onToken = (data) => {
      console.log('Token received:', data.token);
      dispatch(updateCurrentStreamedMessage(data.token));
      dispatch(setTyping(true));
      dispatch(setThinking(false));
      scrollToBottom();
    };
  
    const onMessageEnd = () => {
      dispatch(finishCurrentStreamedMessage());
      dispatch(setTyping(false));
      dispatch(setThinking(false));
      scrollToBottom();
    };
  
    const onError = (data) => {
      console.error('Error from server:', data.message);
      if (data.message?.includes('authentication') || data.message?.includes('rejected')) {
        console.log('Auth error, attempting reconnect');
        socket.disconnect();
        socket.connect();
      }
      dispatch(setTyping(false));
      dispatch(setThinking(false));
      toast.error(`Error: ${data.message}`);
    };
  
    const onConversationReset = () => {
      dispatch(resetConversation());
      dispatch(setTyping(false));
      dispatch(setThinking(false));
      console.log('Conversation has been reset');
    };
  
    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('thinking_start', onThinkingStart);
    socket.on('token', onToken);
    socket.on('message_end', onMessageEnd);
    socket.on('error', onError);
    socket.on('conversation_reset', onConversationReset);
  
    return () => {
        if (socketRef.current) {
          socketRef.current.off('connect', onConnect);
          socketRef.current.off('disconnect', onDisconnect);
          socketRef.current.off('thinking_start', onThinkingStart);
          socketRef.current.off('token', onToken);
          socketRef.current.off('message_end', onMessageEnd);
          socketRef.current.off('error', onError);
          socketRef.current.off('conversation_reset', onConversationReset);
          socketRef.current.disconnect();
          socketRef.current = null;
        }
      };
    }, [dispatch, scrollToBottom, token]);

  // Thinking dots animation
  useEffect(() => {
    let interval;
    if (isThinking) {
      interval = setInterval(() => {
        setThinkingDots(dots => dots.length >= 7 ? '.' : dots + '.');
      }, 300);
    } else {
      setThinkingDots('');
    }
    return () => clearInterval(interval);
  }, [isThinking]);

  // Typing dots animation
  useEffect(() => {
    let interval;
    if (isTyping && !isThinking) {
      interval = setInterval(() => {
        setTypingDots(dots => dots.length >= 7 ? '' : dots + '.');
      }, 300);
    } else {
      setTypingDots('');
    }
    return () => clearInterval(interval);
  }, [isTyping, isThinking]);

  const sendMessage = useCallback(() => {
    if (inputMessage.trim() && isConnected && !isTyping && !isThinking && socket) {
      const newMessage = { text: inputMessage.trim(), isBot: false };
      dispatch(addMessage(newMessage));
      socket.emit('message', { text: inputMessage.trim() });
      dispatch(setInputMessage(''));
      dispatch(setThinking(true));
      setShouldAutoScroll(true);
      scrollToBottom();
    }
  }, [inputMessage, isConnected, isTyping, isThinking, socket, dispatch, scrollToBottom]);

  const handleResetConversation = useCallback(() => {
    if (window.confirm("정말로 대화를 초기화하시겠습니까?") && socket) {
      socket.emit('reset_conversation');
    }
  }, [socket]);

  const handleInputChange = (e) => {
    dispatch(setInputMessage(e.target.value));
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  return (
    <div className="App">
      <div className="nav-bar">
        <h1>K2S3 Chat</h1>
        <div className="nav-user">
          <span>{user?.username}</span>
          <button onClick={handleLogout} className="logout-button">
            로그아웃
          </button>
        </div>
      </div>
      
      <div className="chat-header">
        <button onClick={handleResetConversation} className="reset-button">
          Reset Conversation
        </button>
      </div>
      
      {summary && (
        <div className="summary">
          <h3>Conversation Summary:</h3>
          <ReactMarkdown>{summary}</ReactMarkdown>
        </div>
      )}
      
      <div className="chat-window" ref={chatWindowRef}>
        {messages.map((message, index) => (
          <div key={index} className={`message ${message.isBot ? 'bot' : 'user'}`}>
            <div className="message-icon">
              {message.isBot ? '⚛️' : '💬'}
            </div>
            <div className="message-content">
              <ReactMarkdown
                children={message.text}
                components={{
                  code({node, inline, className, children, ...props}) {
                    const match = /language-(\w+)/.exec(className || '')
                    return !inline && match ? (
                      <SyntaxHighlighter
                        children={String(children).replace(/\n$/, '')}
                        style={solarizedlight}
                        language={match[1]}
                        PreTag="div"
                        {...props}
                      />
                    ) : (
                      <code className={className} {...props}>
                        {children}
                      </code>
                    )
                  }
                }}
              />
            </div>
          </div>
        ))}
        
        {currentStreamedMessage && (
          <div className="message bot">
            <div className="message-icon">⚛️</div>
            <div className="message-content">
              <ReactMarkdown>{currentStreamedMessage}</ReactMarkdown>
            </div>
          </div>
        )}
        
        {isThinking && <div className="thinking-indicator">AI is thinking{thinkingDots}</div>}
        {isTyping && !isThinking && <div className="typing-indicator">AI is typing{typingDots}</div>}
        {isSummarizing && <div className="summarizing-indicator">Summarizing previous conversation...</div>}
        
        <div ref={messagesEndRef} />
      </div>
      
      {!isNearBottom && (
        <button 
          className="scroll-to-bottom"
          onClick={() => {
            setShouldAutoScroll(true);
            scrollToBottom();
          }}
        >
          ↓ Scroll to Bottom
        </button>
      )}
      
      <div className="input-area">
        <textarea
          ref={textareaRef}
          value={inputMessage}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress}
          placeholder="Reply to K2S3..."
          disabled={!isConnected || isTyping || isThinking}
          aria-label="Chat input"
        />
        <button 
          onClick={sendMessage} 
          disabled={!isConnected || isTyping || isThinking || !inputMessage.trim()}
          className={`send-button ${inputMessage.trim() ? 'visible' : ''}`}
          aria-label="Send message"
        >
          <FaPaperPlane />
        </button>
      </div>
      
      <div className="connection-status">
        Status: {isConnected ? (isThinking ? 'AI is thinking...' : isTyping ? 'AI is responding...' : 'Connected') : 'Disconnected'}
      </div>
    </div>
  );
}

export default ChatApp;