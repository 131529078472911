import { createSlice } from '@reduxjs/toolkit';

const chatSlice = createSlice({
  name: 'chat',
  initialState: {
    messages: [],
    inputMessage: '',
    isConnected: false,
    isTyping: false,
    isThinking: false,
    isSummarizing: false,
    summary: '',
    currentStreamedMessage: '',
  },
  reducers: {
    setConnected: (state, action) => {
      state.isConnected = action.payload;
    },
    setTyping: (state, action) => {
      state.isTyping = action.payload;
    },
    setThinking: (state, action) => {
      state.isThinking = action.payload;
    },
    setSummarizing: (state, action) => {
      state.isSummarizing = action.payload;
    },
    setSummary: (state, action) => {
      state.summary = action.payload;
    },
    addMessage: (state, action) => {
      state.messages.push(action.payload);
    },
    updateCurrentStreamedMessage: (state, action) => {
      state.currentStreamedMessage += action.payload;
    },
    finishCurrentStreamedMessage: (state) => {
      if (state.currentStreamedMessage) {
        state.messages.push({ text: state.currentStreamedMessage, isBot: true });
        state.currentStreamedMessage = '';
      }
    },
    setInputMessage: (state, action) => {
      state.inputMessage = action.payload;
    },
    resetConversation: (state) => {
      state.messages = [];
      state.summary = '';
      state.currentStreamedMessage = '';
    },
  },
});

export const {
  setConnected,
  setTyping,
  setThinking,
  setSummarizing,
  setSummary,
  addMessage,
  updateCurrentStreamedMessage,
  finishCurrentStreamedMessage,
  setInputMessage,
  resetConversation,
} = chatSlice.actions;

export default chatSlice.reducer;